import React, { useEffect, useRef, useState, useMemo } from "react";
//i18n

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Filter from "./Filter";
import TableStatistics from "./TableStatistics";
import { getCombineStatYearly } from "../../../helpers/app-backend/statisticsannualbackend_helper";

const StatisticsSurveyResult = ({ t }) => {
  const userName = localStorage.getItem("userName");
  const [dataFilter, setDataFilter] = useState({
    siteCode: [],
    masterCategoryCode: [],
    masterProducerCode: [],
    dataType: "",
    fromYear: "",
    toYear: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [dataTable, setDataTable] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalAllPages, setTotalAllPages] = useState(0);
  const getDataChart = async (dataFilter, paging) => {
    const commonParams = {
      siteCode: dataFilter.siteCode,
      masterCategoryCode: dataFilter.masterCategoryCode,
      masterProducerCode: dataFilter.masterProducerCode,
      dataType: dataFilter.dataType,
      fromYear: dataFilter.fromYear,
      toYear: dataFilter.toYear,
      page: paging.currentPage,
      pageSize: paging.pageSize,
    };
    try {
      const response = await getCombineStatYearly(commonParams);

      console.log("response", response);
      setDataTable(response.data);
      setTotalRows(response.totalElements);
      setTotalAllPages(Math.ceil(response.totalElements / paging.pageSize));
      toastr.success("Lấy dữ liệu thành công");
    } catch (err) {
      toastr.error("Đã có lỗi xảy ra");
      setDataTable([]);
    }
  };
  const onChangeSubmitData = async () => {
    getDataChart(dataFilter, paging);
  };

  useEffect(() => {
    getDataChart(dataFilter, paging);
  }, [paging]);

  return (
    <React.Fragment>
      <Filter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        onChangeSubmitData={onChangeSubmitData}
        paging={paging}
        setPaging={setPaging}
      />
      <TableStatistics
        dataTable={dataTable}
        setPaging={setPaging}
        paging={paging}
        totalRows={totalRows}
        totalAllPages={totalAllPages}
      />
    </React.Fragment>
  );
};

export default withRouter(
  withTranslation(["UserPage", "message"])(StatisticsSurveyResult)
);
